<template>
  <div>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div
          class="d-flex align-self-center justify-center align-center mt-md-5"
        >
          <img
            width="40%"
            src="../../assets/conteudo_aguas_title_02.png"
            alt=""
          />
        </div>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="Notas sobre o Plano de Manejo de Rejeito"
            >
              <template v-slot:conteudo>
                <p>
                  O Rio Gualaxo do Norte é um curso de água do estado de Minas
                  Gerais, Região Sudeste do Brasil, pertencente à bacia do Rio
                  Doce. Sua nascente está localizada na serra do Espinhaço, a
                  uma altitude de cerca de 1.380 metros, no município de Ouro
                  Preto. Em seu percurso, o rio Gualaxo do Norte percorre uma
                  extensão de 60 km, passando por diversas comunidades, até seu
                  encontro com o Rio do Carmo, em Barra Longa.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <span
            @click="showOverlayer.fig1 = !showOverlayer.fig1"
            class="d-flex justify-center align-center"
          >
            <p style="font-size: 0.5em;" class="mb-0 cursor-pointer">
              <strong>ampliar imagem</strong>
            </p>
            <v-icon small color="#000" class="cursor-pointer">
              mdi-magnify-plus-outline
            </v-icon></span
          >
          <v-overlay opacity="1" :value="showOverlayer.fig1">
            <img
              width="60%"
              src="../../assets/conteudo_aguas_rejeito_01.png"
              alt=""
              class="mx-auto"
            />
            <v-btn
              class="mx-auto"
              small
              color="#E06919"
              @click="showOverlayer.fig1 = false"
            >
              fechar
            </v-btn>
          </v-overlay>
          <img width="80%" src="../../assets/conteudo_aguas_rejeito_01.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Figura 1: Mapa da rede fluvial da microbacia do Rio Gualaxo do Norte
            (2022). Mapa elaborado por Jackeline C. Mendes e Ludmila G. N.
            Oliveira.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center flex-column"
        >
          <span
            @click="showOverlayer.fig2 = !showOverlayer.fig2"
            class="d-flex justify-center align-center"
          >
            <p style="font-size: 0.5em;" class="mb-0 cursor-pointer">
              <strong>ampliar imagem</strong>
            </p>
            <v-icon small color="#000" class="cursor-pointer">
              mdi-magnify-plus-outline
            </v-icon></span
          >
          <v-overlay
            opacity="1"
            :value="showOverlayer.fig2"
            class="text-center"
          >
            <img
              width="100%"
              src="../../assets/conteudo_aguas_rejeito_02.png"
              alt=""
            />
            <v-btn small color="#E06919" @click="showOverlayer.fig2 = false">
              fechar
            </v-btn>
          </v-overlay>
          <img width="100%" src="../../assets/conteudo_aguas_rejeito_02.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Figura 2: Mapa da localização das comunidades atingidas em Mariana.
            Mapa elaborado por Jackeline C. Mendes e Ludmila G. N. Oliveira,
            2022.
          </p>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A história do Rio Gualaxo do Norte está relacionada à vida e
                  soberania, mas também com um histórico de violência contra
                  suas águas. Vida e soberania, porque foi através dele que as
                  comunidades conseguiram se estabelecer, produzir e viver na
                  região desbravada há mais de 300 anos, dispondo em suas
                  margens uma ocupação baseada na produção de bens
                  intermediários, como agricultura familiar, a extração da mica,
                  extração de ouro, a pecuária extensiva e a exploração
                  madeireira incentivada pela demanda por carvão vegetal das
                  siderúrgicas. Mas também de violência, porque esse
                  desenvolvimento teve um custo para o rio, principalmente ao se
                  descobrir o potencial minerário da região. Sobre a sua
                  nascente se impõe atualmente a mina de minério de ferro
                  Timbopeba no distrito de Antônio Pereira em Ouro Preto,
                  operada pela empresa Vale S.A.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="6" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p class="mt-1">
                  As substâncias químicas utilizadas no beneficiamento do
                  minério, assim como a drenagem ácida da mina, podem contaminar
                  com manganês, cromo, cádmio, zinco, chumbo, arsênico, dentre
                  outros (SANT’ANA; RIGOTTO, 2020) as águas superficiais e/ou
                  subterrâneas. Essas contaminações podem perdurar décadas ou
                  séculos e se estender por grandes áreas. Se não bastasse a
                  contaminação do seu leito advinda das minas, o Rio Gualaxo do
                  Norte foi drasticamente atingido pela enxurrada de rejeitos
                  provenientes do rompimento da Barragem de Fundão em novembro
                  de 2015.
                </p>
                <p>
                  O rompimento da barragem de rejeito de Fundão provocou a
                  liberação de mais de 60 milhões de metros cúbicos de rejeitos
                  de minério de ferro no meio ambiente (MILANEZ et al., 2016).
                  Segundo estimativas do IBAMA (2015), a área atingida no trecho
                  total chegou a 1.500 hectares, percorrendo mais de 650 km até
                  a foz do rio Doce, em Linhares, no litoral do Espírito Santo.
                </p>
                <p>
                  <router-link
                    tag="a"
                    style="text-decoration: none;"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_aguas_lama' }"
                  >
                    Acesse o infógrafo para entender o volume de rejeito.
                  </router-link>
                </p>
              </template>
            </Card>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center flex-column"
        >
          <img height="100%" src="../../assets/conteudo_aguas_rejeito_03.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Figura 3: Rio Gualaxo do Norte na comunidade de Campinas. Foto:
            Laura Lanna (s.d.).
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="O plano de manejo de rejeito"
            >
              <template v-slot:conteudo>
                <p>
                  A Fundação Renova, entidade privada criada em TTAC,
                  responsável por fazer os estudos, recuperar, mitigar e
                  compensar os impactos causados pelo rompimento de Fundão, é
                  também responsável por gerir e coordenar os 42 programas e
                  projetos socioambientais e socioeconômicos que estão sendo
                  implementados na área impactada do rio Doce e afluentes. A
                  Fundação Renova possuía como propósito conduzir todo o
                  processo de reparação na Bacia do Rio Doce, servindo como um
                  mediador entre a Samarco, o Estado e os moradores atingidos.
                  Porém, em seus programas e projetos, a instituição não
                  prioriza a participação da população atingida e demonstra que
                  a reparação será feita nas condições das próprias mineradoras.
                </p>
                <p>
                  O programa socioambiental de número 23 refere-se ao rejeito e
                  aponta questões relacionadas ao seu manejo, sendo ele
                  utilizado como premissa para a elaboração do Plano de Manejo
                  de Rejeito (PMR)<sup>1</sup> (TTAC, 2016, p. 10). O PMR contém
                  diretrizes, metodologias, técnicas, critérios e indicadores
                  para tomada de decisão quanto ao manejo dos rejeitos,
                  contemplando as áreas afetadas pelo rompimento da barragem de
                  Fundão, sendo que a maior concentração do rejeito se encontra
                  especialmente depositada sobre as planícies e canais fluviais
                  dos cursos d’água entre a barragem de Fundão, em Mariana, e a
                  Usina Hidrelétrica Risoleta Neves, localizada no município de
                  Rio Doce.
                </p>
                <p>
                  O plano não prevê nenhuma participação direta das pessoas
                  atingidas, a qual é restrita às duas instâncias de
                  participação. Os proprietários das terras invadidas pelo
                  rejeito serão apenas comunicados, no final do processo, sobre
                  o que será feito com o rejeito ali depositado. Em nenhum
                  momento é posta como alternativa a participação das pessoas
                  sobre a escolha do que fazer com o rejeito, ou mesmo
                  questionar sobre a implementação da alternativa de manejo
                  previamente selecionada.
                </p>
                <p>
                  Para a tomada de decisão, o PMR utiliza o viés custo/benefício
                  como base, ou seja, essa lógica que irá definir se haverá
                  retirada ou permanência do rejeito no território. A empresa
                  CH2M coloca a Análise de Custo-Benefício como a forma mais
                  simplificada e econômica para a realização da tomada de
                  decisão, e aponta a permanência do rejeito nos leitos dos rios
                  como a mais vantajosa economicamente. Nesse sentido, o PMR
                  considera somente as necessidades econômicas e benéficas à
                  empresa responsável pelo rompimento. Nessa análise não se
                  pondera o uso do solo dessa região, bem como a permanência das
                  pessoas no território. Acrescido a isso, todas as menções
                  sobre as ações definidas no PMR estão voltadas diretamente
                  para o rejeito, não considerando o impacto físico e social que
                  este poderá trazer à área onde for remanejado, ou mesmo caso
                  ele seja deixado ao longo das calhas dos rios, proposta
                  definida pelo PMR, e que foi adotada pela Fundação Renova, em
                  todos os contextos do município de Mariana.
                </p>
                <p>
                  Em contraponto a essa escolha, o PMR faz análise sobre a
                  composição física do solo após o rompimento. Segundo valores
                  apontados nas análises, a fertilidade do solo após o
                  rompimento encontra-se comprometida<sup>2</sup>.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex justify-center align-center flex-column"
        >
          <img height="100%" src="../../assets/conteudo_aguas_rejeito_04.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Figura 5: Remanescente de lama na comunidade de Paracatu de Baixo.
            Foto: Acervo Cáritas (s.d.).
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="A contaminação"
            >
              <template v-slot:conteudo>
                <p>
                  Foram realizados alguns estudos sobre a contaminação das águas
                  do Rio Gualaxo, tanto pela Fundação Renova, quanto por órgãos
                  oficiais do poder público. O estudo da Ambios<sup>3</sup>
                  comprovou que o ar e o solo dos territórios atingidos estão
                  contaminados pelos metais cádmio, cobre, zinco e níquel, esse
                  último apenas em Barra Longa.
                </p>
                <p>
                  O PMR identifica que o solo analisado apresenta baixo teor de
                  matéria orgânica e muito baixa reserva de macronutrientes:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center mt-4">
        <v-col
          cols="12"
          md="8"
          class="d-flex justify-center align-center border__image"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            Com relação à análise dos parâmetros químicos para fertilidade do
            solo, observou-se que todos os resultados para matéria orgânica e
            carbono orgânico se enquadraram como “baixos” quando comparados aos
            valores de referência adotados para Minas Gerais, assim como os
            nutrientes cálcio (Ca) e magnésio (Mg), indicando solos pobres
            nestes macronutrientes. [...] As análises indicaram solos pobres,
            com baixa fertilidade e pequenas alterações entre um transecto e
            outro. No geral as amostras apresentaram resultados com baixo teor
            de argila (menor que 10%), baixo teor de matéria orgânica e muito
            baixa reserva dos macronutrientes quais sejam, cálcio, magnésio e
            potássio, nas amostras analisadas (JACOBS CH2M, 2018, p. 452-453).
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Devido à tradição agrícola da região, essa mudança na
                  fertilidade do solo tem importante impacto no sustento das
                  pessoas atingidas, bem como no modo de vida da região
                  atingida, que se apresenta diretamente relacionada à produção
                  agrícola. Esse fato pode ser observado no relato de um
                  atingido: “após o rompimento não tem mais como plantar ou
                  pescar porque agora lá ficou lama pura, e que agora estão
                  tentando recuperar a beira do rio, mas que a terra que tem lá
                  não é mais terra fértil como era a terra que tinha
                  antes”<sup>4</sup>.
                </p>
                <p>
                  Quanto à análise da qualidade da água, os PMR apresentam dados
                  em desconformidade com os parâmetros estabelecidos pelo COPAM
                  01/08 para consumo humano e animal, além de apresentar
                  contaminação por arsênio, níquel, cromo e outros metais
                  agravando consideravelmente a situação. De acordo com Torres
                  et al. (2017, p. 4), os metais pesados estão entre os
                  poluentes que têm a capacidade de se acumular na flora e
                  fauna. Sendo que, a dieta alimentar é uma das principais vias
                  de exposição aos metais pesados. Neste sentido, é ignorado o
                  risco gerado à saúde humana que o rejeito pode provocar.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center mt-4">
        <v-col
          cols="12"
          md="8"
          class="d-flex justify-center align-center border__image2"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            Sempre me senti incomodado com a questão da contaminação, porque a
            gente viu, em Barra Longa, as pessoas contaminadas. E a gente viu
            nossos amigos morrendo. Pessoas muito próximas, vizinhos. Num lugar
            tão pequeno, nível de câncer altíssimo, quatro pessoas com câncer,
            três já morreram. Pessoas novas, pessoas saudáveis, pessoas que
            estavam na luta com a gente, e a empresa sempre negando que tem
            contaminação. (Marino D’Angelo, morador de Paracatu de Cima. A
            sirene, 44ª ed, p. 09).
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  O estudo ainda indica que, como a presença de metais pesados
                  no meio hídrico é um fator preocupante à saúde pública, há a
                  necessidade de monitoramento constante do ambiente afetado,
                  bem como da remediação ou recuperação a ser indicada com base
                  nos resultados dos parâmetros alterados. Os autores afirmam
                  que “a escavação de poços, e sua posterior utilização se deu
                  por conta do derramamento da lama na água do Rio, que por
                  ventura, a inutilizou” (TORRES et al., 2017, p. 20), o que
                  sugere contaminação no lençol freático e consequente exposição
                  das pessoas atingidas a esses metais.
                </p>
                <p>
                  Os relatos das Tomadas de Termo (realizada na etapa 5 do
                  Cadastramento) também são marcados pela presença constante de
                  medo e insegurança sobre o consumo dos alimentos produzidos na
                  região: “perdemos as árvores frutíferas e nem sabemos se é
                  saudável consumir esses frutos que dão hoje, há muita poeira.
                  Tenho um amigo que passou por uma bateria de exames que
                  demonstrou que está contaminado por metais
                  pesados”<sup>5</sup>. Outro atingido relata que “depois do
                  rompimento não voltou na cachoeira, que não tem coragem de
                  entrar nas águas por medo de contrair alguma doença de
                  pele”<sup>6</sup>.
                </p>
                <p>
                  Por essa razão se fazem necessários estudos independentes em
                  relação à contaminação das águas do Rio Gualaxo e demais
                  cursos hídricos que sofreram com a enxurrada de lama em 2015.
                  Dulce Maria Pereira, professora e pesquisadora da Universidade
                  Federal de Ouro Preto, chama a atenção para o fato de que
                  muitas vezes o elemento está abaixo dos níveis aceitáveis
                  pelos órgãos oficiais, mas com uma constante exposição ele se
                  torna um problema de saúde.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center mt-4">
        <v-col
          cols="12"
          md="8"
          class="d-flex justify-center align-center border__image3"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            O problema é que um ambiente contaminado tem que ser controlado, e
            não controlar é crime. Porque se não há controle da contaminação, a
            gente não tem controle da dispersão da contaminação (Dulce Pereira
            para o Jornal Sirene, 2019).
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Para as assessoras técnicas que compõem a equipe da Cáritas em
                  Mariana, é impossível ouvir os relatos dos atingidos e
                  atingidas, visitar as comunidades e ainda assim negar que
                  houve contaminação do meio ambiente. Em muitos relatos das
                  pessoas atingidas que participaram do processo de
                  cadastramento, especialmente os das comunidades de Paracatu,
                  Bento e Camargos, foi enfatizado a morte dos animais após o
                  consumo da água do rio contaminado ou mesmo o nascimento de
                  filhotes com deformações. Os garimpeiros relataram problemas
                  relacionados à pele, como coceira ou vermelhidão após o
                  contato com o rejeito.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img width="80%" src="../../assets/conteudo_aguas_rejeito_08.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Figura 6: Dessedentação animal no Rio Gualaxo do Norte. Foto: José
            Cruz, Agência Brasil/2017.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Essas incertezas com relação à contaminação tornam o processo
                  ainda mais dolorido e problemático para as pessoas atingidas,
                  pois a vida delas está naquele lugar/território e não há
                  nenhum posicionamento das empresas quanto a essa situação.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center mt-4">
        <v-col
          cols="12"
          md="8"
          class="d-flex justify-center align-center border__image4"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            A gente deixa uma água no copo, ou em alguma outra vasilha, de hoje
            pra amanhã. E, quando joga a água fora, aquela vasilha tá
            escorregando. A gente lava a vasilha e, se você não passar um
            Bombril com força e detergente, o copo não fica limpo. O copo fica
            amarelinho, amarelinho. Então a nossa preocupação é essa: porque se
            o copo fica daquele jeito, e o nosso organismo, como tá? A gente tem
            que falar, reclamar, porque a gente tá cansado. Quatro anos não são
            quatro dias. Eles fingem que não ouvem a gente. Tem criança e tem
            mais gente idosa acima de 50, 60 anos, igual eu. (Cleusa da Silva
            Gomes, moradora de Gesteira. A sirene, 44ª ed, p.08).
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center flex-column"
        >
          <img width="100%" src="../../assets/conteudo_aguas_rejeito_10.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Figura 7: Fauna e rio devastados após o rompimento. Foto: Márcio
            Fernandes/Estadão.
          </p>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="A população e os usos após o rompimento"
            >
              <template v-slot:conteudo>
                <p>
                  Ao longo desses quatro os anos do processo de cadastramento,
                  tornou-se perceptível dois tipos de posicionamento das pessoas
                  atingidas, especialmente as que não se deslocaram do
                  território: as que têm medo da contaminação e não fazem o uso
                  dos recursos hídricos, não plantam e não consomem alimentos da
                  região; e os que acreditam que não existe contaminação e
                  continuam consumindo alimentos produzidos sob o rejeito.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  O primeiro grupo, maioria entre as pessoas atingidas, enfatiza
                  a morte das árvores, a baixa fertilidade do solo, o
                  aparecimento de doenças de pele e respiratórias, como pode ser
                  visto nos relatos feitos por eles:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center mt-4">
        <v-col
          cols="12"
          md="8"
          class="d-flex flex-column justify-center align-center border__image5"
          style="z-index: 10;"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            Gostava de pescar na comunidade, mas depois do rompimento não voltei
            A água está contaminada e não posso consumir os peixes. Soube que
            até o leite da vaca está contaminado, estão cheios de metais
            pesados<sup>7</sup>.
          </p>
          <p style="z-index: 15; text-align: center">
            Tive contato com lama, quando retirei os pertences das pessoas, nas
            casas atingidas pela lama e, por isso, deu algumas bolhas no meu
            braço. Tenho dúvida sobre o contato direto com a lama e receio que
            esse contato traga problema de saúde futuro<sup>8</sup>.
          </p>
          <p style="z-index: 15; text-align: center">
            Agora acabou. Acabou tudo... Nem criação pode beber água. Não posso
            mais plantar, é por causa do rejeito, né?<sup>9</sup>
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Porém, o relatório da AMBIOS, o qual realizou entrevista com
                  profissionais de saúde da área atingida, aponta que, mesmo com
                  insegurança sobre a contaminação alguns atingidos ainda
                  continuam “plantando frutas e legumes em áreas atingidas pela
                  lama, e além de consumirem a produção, distribuem, trocam
                  entre si e vendem os produtos na feira da cidade” (ARSH, 2019,
                  p. 168). Percebe-se, assim, nesse segundo grupo, um mecanismo
                  de defesa ou mesmo uma negação quanto aos inúmeros relatos
                  aqui já mencionados. A aceitação dessa nova realidade poderá
                  implicar em outros novos impactos na vida dessas pessoas como
                  a perda da referência de lugar, de relações sociais, perda do
                  contato com a terra.
                </p>
                <p>
                  Essa dinâmica de incertezas marca a vivência das pessoas
                  atingidas em todo o território, que além de viverem o impacto
                  físico, psicológico e econômico do desastre-crime, convivem
                  diariamente com a falta de informação verídica sobre esse
                  material, que agora compõe a sua história.
                </p>
                <p style="text-align: center;">
                  <strong
                    >Autoras: Laura Lanna Carneiro <sup>10</sup> e Priscila
                    Aparecida da Rocha Honorato <sup>11</sup>
                  </strong>
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> Para a aplicação e execução do PMR, o plano indica 5
              fases, são elas: Fase 1 – Caraterização do processo de transporte
              e deposição de rejeito em toda área impactada; Fase 2 – Tomada de
              Decisão e Seleção das Alternativas de Manejo; Fase 3 - Avaliação
              Governamental da Proposta Apresentada; Fase 4 – Comunicação aos
              Proprietários; e Fase 5 – Implementação e Monitoramento da
              Alternativa Selecionada. Ele aponta ainda que na segunda fase
              poderão ser incorporadas resolutivas propostas nas duas instâncias
              de participação (Comitê Interfederativo e Câmara Técnica) porém,
              delimita a resolutiva final sobre o manejo do rejeito somente a
              órgãos ambientais.
            </p>
            <p>
              <sup>2</sup> Os valores do pH estão acima da faixa neutra (5,5 –
              6,5) e apontam para um processo de alcalinidade do solo. O pH
              determina a disponibilidade de nutrientes para as plantas, sendo
              que baixos valores (menor que 5,5) têm a disponibilidade de
              macronutrientes para a planta diminuída, e quando muito alto
              (maior que 7) têm a disponibilidade de micronutrientes para a
              planta diminuída (JACOBS, 2018, p. 453).
            </p>
            <p>
              <sup>3</sup> Empresa contratada pela Fundação Renova/Samarco/Vale/
              BHP Billiton.
            </p>
            <p>
              <sup>4</sup> Citação de um atingido retirada da Tomada de Termo —
              etapa 5 do processo de Cadastramento das pessoas atingidas.
            </p>
            <p>
              <sup>5</sup> Citação de um atingido retirada da Tomada de Termo —
              etapa 5 do processo de Cadastramento das pessoas atingidas.
            </p>
            <p>
              <sup>6</sup> Citação de um atingido retirada da Tomada de Termo —
              etapa 5 do processo de Cadastramento das pessoas atingidas.
            </p>
            <p>
              <sup>7</sup> Citação de um atingido retirada da Tomada de Termo —
              etapa 5 do processo de Cadastramento das pessoas atingidas.
            </p>
            <p>
              <sup>8</sup> Citação de um atingido retirada da Tomada de Termo —
              etapa 5 do processo de Cadastramento das pessoas atingidas.
            </p>
            <p>
              <sup>9</sup> Citação de um atingido retirada na Cartografia
              Social— etapa 2 do processo de Cadastramento das pessoas
              atingidas.
            </p>
            <p>
              <sup>10</sup> Arquiteta e Urbanista pela UFOP, mestra em Geografia
              pela UFJF. Assessora técnica na ATI Mariana.
            </p>
            <p>
              <sup>11</sup> Engenheira Agrônoma pela UFV. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <strong>Referências Bibliográficas</strong>
            </p>
            <p>
              ARSH -
              <strong
                >Avaliação Do “Relatório Técnico - Avaliação De Risco À Saúde
                Humana Metodologia Ministério Da Saúde - Mariana - MG</strong
              >
              executado pela Ambios. Julho 2019.
            </p>
            <p>
              GOLDER ASSOCIATES.
              <strong
                >Relatório da Avaliação dos Impactos no Meio Físico Resultantes
                do Rompimento da Barragem de Fundão</strong
              >. 2016
            </p>
            <p>
              IBAMA - Instituto Brasileiro do Meio Ambiente e dos Recursos
              Naturais Renováveis; DIPRO - Diretoria de Proteção Ambiental;
              CGEMA - Coordenação Geral de Emergências Ambientais.
              <strong
                >Laudo Técnico Preliminar - Impactos ambientais decorrentes do
                desastre envolvendo o rompimento da barragem de Fundão, em
                Mariana, Minas Gerais</strong
              >. Novembro de 2015.
            </p>
            <p>
              JACOBS CH2M,
              <strong>Plano de Manejo de Rejeito – volume 4</strong>. Preparado
              para a Fundação Renova. São Paulo: 2018a.
            </p>
            <p>
              Ministério Público Federal, Ministério Público do Estado de Minas
              Gerais, Ministério Público do Estado do Espírito Santo, Defensoria
              Pública da União, Defensoria Pública do Estado de Minas Gerais,
              Defensoria Pública do Estado do Espírito Santo, . . . Fundação
              Renova. <strong>Termo de Ajustamento de Conduta</strong>. Belo
              Horizonte. 2018.
            </p>
            <p>
              Ministério Público Federal, Ministério Público do Estado de Minas
              Gerais, Samarco Mineracão S.A., Vale S.A., & BHP Billiton Brasil
              Ltda. (2017).
              <strong>Termo Aditivo ao Termo de Ajustamento Preliminar</strong>
              (TAP). Belo Horizonte.
            </p>
            <p>
              TORRES, et al.
              <strong
                >Contaminação por metais pesados na água utilizada por
                agricultores familiares na Região do Rio Doce. Relatório
                Final</strong
              >. Universidade Federal do Rio de Janeiro, 2017.
            </p>
            <p>
              A sirene. 44ª ed, p. 08-09, 2019. Dispnível em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="https://issuu.com/jornalasirene/docs/dezembro_2019_issuu"
                target="_blank"
                >https://issuu.com/jornalasirene/docs/dezembro_2019_issuu</a
              >
              . Acessado em: 29 de abril de 2022.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_aguas_lama' }"
            color="#EDBD43"
          >
            <strong>Conhecer “Lama”</strong>
          </v-btn>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Águas /",
          href: "conteudos_tematicos_aguas",
        },
        {
          text: "Rejeito e Toxidade",
          href: "conteudos_tematicos_aguas_rejeito",
        },
      ],

      showOverlayer: {
        fig1: false,
        fig2: false,
      },
    };
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

@media (max-width: 600px) {
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .border__image {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
    color: black !important;
  }

  .border__image2 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }

  .border__image2 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
    color: black !important;
  }

  .border__image3 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image3::before {
    display: none;
  }

  .border__image3 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
    color: black !important;
  }

  .border__image4 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image4::before {
    display: none;
  }

  .border__image4 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
    color: black !important;
  }

  .border__image5 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image5::before {
    display: none;
  }

  .border__image5 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
    color: black !important;
  }
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/conteudo_aguas_rejeito_05.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 80%;
  color: white;
  padding: 1em 3em 0 1em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/conteudo_aguas_rejeito_06.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 80%;
  color: white;
  padding: 1em 3em 0 3em;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/conteudo_aguas_rejeito_07.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 80%;
  color: white;
  padding: 0 3em 0 3em;
}

.border__image4 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image4::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/conteudo_aguas_rejeito_09.png");
  background-size: 100% 100%;
}

.border__image4 p {
  font-size: 0.6em;
  max-width: 80%;
  color: white;
  padding: 1em 3em 0 1em;
}

.border__image5 {
  width: 100%;
  padding: 3em 2em 2em 2em;
  position: relative;
}

.border__image5::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/conteudo_aguas_rejeito_05.png");
  background-size: 100% 100%;
}

.border__image5 p {
  font-size: 0.6em;
  max-width: 80%;
  color: white;
  padding: 1em 3em 0 1em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
